import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import CustomInputField from '../../hoc/CustomInputField';
import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import CustomLink from '../../hoc/CustomLink';
import { valdaUrl, accountID } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import { handleResponseCode, showAlert, handleException } from '../../utility';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class SignupLinkAccount extends Component {
  constructor(props) {
    super(props);
    console.log('Props:', this.props);
    this.state = {
      password: '',
      errorpassword: false,
      passwordErrorMessage: '',
      navigateToLogin: false,
    };
  }

  // Validation For Sign Up Page
  validateSignUp = () => {
    if (this.state.password === '') {
      this.setState({
        errorpassword: true,
        passwordErrorMessage: "Password can't be blank.",
      });
      return false;
    }
    return true;
  };

  // SignUp Api Call
  handleSignup = () => {
    if (!this.validateSignUp()) {
      return;
    }

    const url = `${valdaUrl}signup`;
    const apiData = {
      email: localStorage.getItem('email'),
      already_registered: 1,
      account_id: localStorage.getItem(accountID),
      password: this.state.password,
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          this.setState({
            navigateToLogin: true,
          });
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleGetInTouch = () => {
    window.open('tel:03303904510', '_system');
  };

  // Input field handle - Password
  handlePasswordChange = (newValue) => {
    this.setState({
      password: newValue,
      errorpassword: false,
      passwordErrorMessage: '',
    });
  };

  render() {
    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="SIGN UP" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <div className="centerText marginTop">
            Link this account ID to your existing Valda account.
          </div>
          <CustomInputField
            fieldName="Password"
            fieldType="password"
            fieldValue={this.state.password}
            isError={this.state.errorpassword}
            errorMessage={this.state.passwordErrorMessage}
            textChange={this.handlePasswordChange}
          />
          <CustomButton
            btnName="LINK ACCOUNT"
            btnClick={this.handleSignup}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
          <CustomLink
            linkText="GET IN TOUCH"
            linkClick={this.handleGetInTouch}
          />
        </Grid>
      </div>
    );
  }
}

export default SignupLinkAccount;
