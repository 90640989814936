import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import './LeftMenu.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import PublishIcon from '@material-ui/icons/PublishOutlined';

import CustomButton from '../../hoc/CustomButton';
import HomeNavigation from '../HomeNavigation/HomeNavigation';
import InvoiceList from '../InvoiceList/InvoiceList';
import PaymentList from '../PaymentList/PaymentList';
import HomePage from '../HomePage/HomePage';
import FAQ from '../FAQ/FAQ';
import Transactions from '../Transactions/Transactions';
import Usage from '../Usage/Usage';
import { POSTSeaglass } from "../../Global/Apis/ServerApi";
import {
  ddSignupURL,
  meterReadSubmissionURL,
  meterReadWebsiteSubmissionURL,
  encryptedAccountID,
  accountsList,
  accountID,
  seaglassURL,
} from "../../constants";
import Sites from '../Sites/Sites';
import ContactUs from '../ContactUs/ContactUs';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import AccountInformation from '../AccountInformation/AccountInformation';
import * as actions from '../../store/actions/index';
import MakePayment from '../Payment/MakePayment/MakePayment';
import AlternativePayment from '../Payment/AlternativePayment/AlternativePayment';
import DirectDebit from '../Payment/DirectDebit/DirectDebit';
import InvoiceDetails from '../InvoiceDetails/InvoiceDetails';
import ViewInvoice from '../InvoiceDetails/ViewInvoice';
import {
  removeTokenData,
  logEvent,
  handleResponseCode,
} from "../../utility";


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  smallListItemText: {
    fontSize: '0.9rem',
  },
}));

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      accountID: localStorage.getItem(accountID),
      companyName: localStorage.getItem('companyName'),
      currentView: "home",
      selectedinvoice: {},
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
    return true;
  };

  handleMakePayment = (stopToggleDrawer) => {
    this.setState({ currentView: "makepayment" });
    if (!stopToggleDrawer) this.handleDrawerToggle();
  };

  handleMakePaymentMenu = (stopToggleDrawer) => {
    this.setState({ currentView: "makepayment" });
    if (stopToggleDrawer) this.handleDrawerToggle();
  };

  handleSubmitMeterReadMenu = (stopToggleDrawer) => {
    this.setState({ currentView: "meterread" });
    if (stopToggleDrawer) this.handleDrawerToggle();
  };

  changeView = () => {
    switch (this.state.currentView) {
      case "home":
        return (
          <HomePage
            menuClick={this.handleDrawerToggle}
            goToInvoice={this.handleInvoice}
            goToPayment={this.handleMakePayment}
            goToPayments={this.handlePayment}
            accountID={this.state.accountID}
          />
        );
      case "invoice":
        return (
          <InvoiceList
            menuClick={this.handleDrawerToggle}
            makePaymentClick={this.handleMakePayment}
            goToInvoiceDetails={this.handleInvoiceDetails}
            accountID={this.state.accountID}
          />
        );
      case "invoicedetails":
        return (
          <InvoiceDetails
            goToInvoiceList={this.handleInvoice}
            viewPdf={this.handleInvoiceDownload}
            invoiceObj={this.state.selectedinvoice}
          />
        );
      case "invoicepdf":
        return (
          <ViewInvoice
            goToInvoiceList={this.handleInvoice}
            viewPdf={this.handleInvoiceDownload}
            invoiceObj={this.state.selectedinvoice}
          />
        );
      case "payment":
        return (
          <PaymentList
            menuClick={this.handleDrawerToggle}
            makePaymentClick={this.handleMakePayment}
            goToInvoiceDetails={this.handleInvoiceDetails}
          />
        );
      case "transactions":
        return <Transactions menuClick={this.handleDrawerToggle} />;
      case "faq":
        return <FAQ menuClick={this.handleDrawerToggle} />;
      case "usage":
        return <Usage menuClick={this.handleDrawerToggle} />;
      case "account":
        return <AccountInformation menuClick={this.handleDrawerToggle} />;
      case "sites":
        return <Sites menuClick={this.handleDrawerToggle} />;
      case "contactus":
        return <ContactUs menuClick={this.handleDrawerToggle} />;
      case "terms":
        return <TermsAndConditions menuClick={this.handleDrawerToggle} />;
      case "logout":
        return <Redirect to="/" />;
      case "makepayment":
        return (
          <MakePayment
            showAlternativePayment={this.showAlternativePayment}
            showDirectDebitSetup={this.showDirectDebitSetup}
            menuClick={this.handleDrawerToggle}
          />
        );
      case "alternativepayment":
        return <AlternativePayment menuClick={this.handleDrawerToggle} />;
      case "directdebitsetup":
        return (
          <DirectDebit
            showMakePayment={this.showMakePayment}
            showContactUs={this.showContactUs}
            showDDScreen={this.showDDScreen}
            menuClick={this.handleDrawerToggle}
          />
        );
      default:
        return (
          <HomePage
            menuClick={this.handleDrawerToggle}
            goToInvoice={this.handleInvoice}
            goToPayment={this.handleMakePayment}
          />
        );
    }
  };

  showAlternativePayment = () => {
    this.setState({ currentView: "alternativepayment" });
  };

  showMakePayment = () => {
    this.setState({ currentView: "makepayment" });
  };

  showDirectDebitSetup = () => {
    this.setState({ currentView: "directdebitsetup" });
  };

  handleHome = () => {
    this.setState({ currentView: "home" });
    this.handleDrawerToggle();
  };

  handleInvoice = (stopToggleDrawer) => {
    this.setState({ currentView: "invoice" });
    if (stopToggleDrawer) this.handleDrawerToggle();
  };

  handlePayment = (stopToggleDrawer) => {
    this.setState({ currentView: "payment" });
    if (stopToggleDrawer) this.handleDrawerToggle();
  };

  handleTransactions = (stopToggleDrawer) => {
    this.setState({ currentView: "transactions" });
    if (stopToggleDrawer) this.handleDrawerToggle();
  };

  handleInvoiceDetails = (invoiceObj) => {
    console.log("=-=-=-invoiceObj=-=-", invoiceObj);
    this.setState({
      currentView: "invoicedetails",
      selectedinvoice: invoiceObj,
    });
  };

  handleInvoiceDownload = (invoiceObj) => {
    console.log("=-=-=-invoiceObj=-=-", invoiceObj);
    this.setState({
      currentView: "invoicepdf",
      selectedinvoice: invoiceObj,
    });
  };

  handleFAQ = () => {
    this.setState({ currentView: "faq" });
    this.handleDrawerToggle();
  };

  handleUsage = () => {
    this.setState({ currentView: "usage" });
    this.handleDrawerToggle();
  };

  handleAccount = () => {
    this.setState({ currentView: "account" });
    this.handleDrawerToggle();
  };

  handleSites = () => {
    this.setState({ currentView: "sites" });
    this.handleDrawerToggle();
  };

  handleContactUs = () => {
    this.setState({ currentView: "contactus" });
    this.handleDrawerToggle();
  };

  showContactUs = () => {
    this.setState({ currentView: "contactus" });
  };

  handleTermsAndConditions = () => {
    this.setState({ currentView: "terms" });
    this.handleDrawerToggle();
  };

  showDDScreen = () => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        `${ddSignupURL}${localStorage.getItem(encryptedAccountID)}`,
        "_blank",
        "location=yes"
      );
    } else {
      window.open(
        `https://www.valdaenergy.com/set-up-a-direct-debit?account_id=${localStorage.getItem(
          encryptedAccountID
        )}`,
        "_system"
      );
    }
  };

  handlePrivacyPolicy = () => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(
        "https://www.valdaenergy.com/privacy",
        "_blank",
        "location=no"
      );
    } else {
      window.open("https://www.valdaenergy.com/privacy", "_system");
    }
    this.handleDrawerToggle();
  };

  handleLogout = () => {
    // eslint-disable-next-line react/prop-types
    this.props.onLogout();
    removeTokenData();
    this.handleDrawerToggle();
    this.setState({ currentView: "logout" });
  };

  openMeterRead = () => {
    logEvent("open_submit_meter_read");
    if (window.cordova) {
      let meter_read_window = window.cordova.InAppBrowser.open(
        `${meterReadSubmissionURL}${localStorage.getItem(encryptedAccountID)}`,
        "_blank",
        "location=yes"
      );
      meter_read_window.addEventListener("loadstart", function (event) {
        if (
          event.url ===
          `${meterReadSubmissionURL}${localStorage.getItem(
            encryptedAccountID
          )}#close`
        ) {
          meter_read_window.close();
        }
      });
    } else {
      window.open(
        `${meterReadWebsiteSubmissionURL}${localStorage.getItem(
          encryptedAccountID
        )}`,
        "_system"
      );
    }
  };

  changeBottomBarActiveTab = (activeTab) => {
    if (activeTab === "meterread") {
      this.openMeterRead();
    } else {
      this.setState({ currentView: activeTab });
    }
  };

  handleLiveChat = () => {
    const url = `${seaglassURL}sgBill/accounts/${localStorage.getItem(
      accountID
    )}`;
   
    POSTSeaglass(url)
      .then((response) => {
        if (handleResponseCode(response)) {
          if (response.response.data) {
            const firstname = response.response.data.contact.firstname;
            const lastname = response.response.data.contact.lastname;
            const email = response.response.data.contact.email;
            const mobile = response.response.data.contact.mobile;
            logEvent("start_live_chat");
            if (window.cordova) {
              const url = `https://home-e32.niceincontact.com/inContact/ChatClient/ChatClient.aspx?poc=0dec6676-8a5e-4fdc-8d43-02ad58d7288a&bu=4599065&P0=${firstname}&P2=${lastname}&P3=${email}&P4=${mobile}`;
              window.cordova.InAppBrowser.open(url, "_blank", "location=no");
              return;
            }
            window.icPatronChat.init({
              serverHost: "https://home-e32.niceincontact.com",
              bus_no: 4599065,
              poc: "0dec6676-8a5e-4fdc-8d43-02ad58d7288a",
              params: [
                firstname,
                lastname,
                email,
                mobile,
              ],
              customChatButton: true,
            });

            setTimeout(() => {
              window.icPatronChat.setStateWindowToggle();
            }, 1000);
          }
        }
      })
      .catch((error) => {
      });
  };
  

  handleAccountIDChange = (event) => {
    localStorage.setItem(accountID, event.target.value.toString());
    const accountsArray = JSON.parse(localStorage.getItem(accountsList));
    accountsArray.forEach(function (obj) {
      if (obj.account_id === event.target.value.toString()) {
        localStorage.setItem(encryptedAccountID,obj.encrypted_id);
        localStorage.setItem("accountName", obj.companyName);
      }
    });
    const returnView = this.state.currentView;
    let switchView = 'home';
    // Need to switch to one screen and then to another, otherwise the page won't rerender and it cannot be the same screen as you're on
    if(returnView === "home") {
       switchView = 'faq';
    }

    this.setState(
      {
        accountID: event.target.value.toString(),
        companyName: localStorage.getItem('accountName'),
        currentView: switchView,
        menuOpen: false,
      },
      () => {
        this.setState({
          currentView: returnView,
          menuOpen: false,
        });
      }
    );
  }


  render() {
    const companyName = localStorage.getItem('companyName');
    const currentID = this.state.accountID ? this.state.accountID : "";
    const multipleAccounts = localStorage.getItem("multipleAccounts");
    let accountIDSList = [];
    if (localStorage.getItem(accountsList)) {
      if(localStorage.getItem(accountsList)) {
        accountIDSList = JSON.parse(localStorage.getItem(accountsList)).map(
          (account) => (
            <option key={account.account_id} value={account.account_id}>
              {`${account.companyName} (${account.account_id})`}
            </option>
          )
        );
      }
    }
    const drawer = (
      <div style={{ width: drawerWidth }}>
        <List>
          <div className="userNameContainer">
            <span
              className="userName"
              style={{ display: multipleAccounts === "1" ? "none" : "block" }}
            >
              {companyName} ({currentID})
            </span>
            <TextField
              select
              label="Switch account"
              size="large"
              className="switchAccount"
              value={this.state.accountID}
              onChange={this.handleAccountIDChange}
              SelectProps={{
                native: false,
              }}
              variant="outlined"
              style={{
                marginTop: "1em",
                marginLeft: "5px",
                marginBottom: "1em",
                minWidth: "15em",
                width: "97%",
                display: multipleAccounts === "1" ? "block" : "none",
              }}
            >
              {accountIDSList}
            </TextField>
            <span className="closeMenu dontShowOnDesktop">
              <IconButton
                onClick={this.handleDrawerToggle}
                className={useStyles.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </div>
          <Divider />
          <ListItem
            button
            className={this.state.currentView === "home" ? "activeItem" : ""}
            onClick={this.handleHome}
          >
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem
            button
            className={this.state.currentView === "invoice" ? "activeItem" : ""}
            onClick={this.handleInvoice}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Invoices"
              classes={{ primary: useStyles.smallListItemText }}
            />
          </ListItem>
          <ListItem
            button
            className={this.state.currentView === "payment" ? "activeItem" : ""}
            onClick={this.handlePayment}
          >
            <ListItemIcon>
              <PaymentOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Payments"
              classes={{ primary: useStyles.smallListItemText }}
            />
          </ListItem>
          <ListItem
            button
            className={
              this.state.currentView === "transactions" ? "activeItem" : ""
            }
            onClick={this.handleTransactions}
          >
            <ListItemIcon>
              <ViewListOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Transactions"
              classes={{ primary: useStyles.smallListItemText }}
            />
          </ListItem>
          <ListItem
            button
            className={
              this.state.currentView === "makepayment" ? "activeItem" : ""
            }
            onClick={this.handleMakePaymentMenu}
          >
            <ListItemIcon>
              <ReceiptOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Make a Payment"
              classes={{ primary: useStyles.smallListItemText }}
            />
          </ListItem>

          <ListItem
            button
            className={
              this.state.currentView === "submitmeterread" ? "activeItem" : ""
            }
            onClick={this.openMeterRead}
          >
            <ListItemIcon>
              <PublishIcon />
            </ListItemIcon>
            <ListItemText
              primary="Submit Meter Read"
              classes={{ primary: useStyles.smallListItemText }}
            />
          </ListItem>

          <ListItem
            button
            className={
              this.state.currentView === "directdebitsetup" ? "activeItem" : ""
            }
            onClick={this.showDirectDebitSetup}
          >
            <ListItemIcon>
              <AccountBalanceOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary="Direct Debit"
              classes={{ primary: useStyles.smallListItemText }}
            />
          </ListItem>

          <ListItem
            button
            className={this.state.currentView === "usage" ? "activeItem" : ""}
            onClick={this.handleUsage}
          >
            <ListItemIcon>
              <DataUsageOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Usage" />
          </ListItem>

          <ListItem
            button
            className={this.state.currentView === "account" ? "activeItem" : ""}
            onClick={this.handleAccount}
          >
            <ListItemIcon>
              <PermIdentityOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>

          <ListItem
            button
            className={this.state.currentView === "sites" ? "activeItem" : ""}
            onClick={this.handleSites}
          >
            <ListItemIcon>
              <LocationOnOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Meter Points" />
          </ListItem>

          <ListItem
            button
            className={this.state.currentView === "faq" ? "activeItem" : ""}
            onClick={this.handleFAQ}
          >
            <ListItemIcon>
              <EventNoteOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="FAQs" />
          </ListItem>

          <ListItem
            button
            className={
              this.state.currentView === "contactus" ? "activeItem" : ""
            }
            onClick={this.handleContactUs}
          >
            <ListItemIcon>
              <ContactPhoneOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItem>

          <Divider light />

          <div style={{ paddingLeft: "16px" }}>
            <CustomButton btnName="Live Chat" btnClick={this.handleLiveChat} />
          </div>
          <div style={{ paddingTop: "25px" }}>
            <ListItem
              button
              className={this.state.currentView === "terms" ? "activeItem" : ""}
              onClick={this.handleTermsAndConditions}
            >
              <ListItemText
                primary="Terms & Conditions"
                className="otherItem"
              />
            </ListItem>
          </div>
          <ListItem
            button
            className={this.state.currentView === "privacy" ? "activeItem" : ""}
            onClick={this.handlePrivacyPolicy}
          >
            <ListItemText primary="Privacy" className="otherItem" />
          </ListItem>

          <ListItem button onClick={this.handleLogout}>
            <ListItemText primary="Log Out" className="otherItem" />
          </ListItem>

          <Divider />

          <div style={{ paddingLeft: "16px" }}>
            <CustomButton
              btnName="MAKE A PAYMENT"
              btnClick={this.handleMakePayment}
            />
          </div>
        </List>
      </div>
    );
    return (
      <div>
        <CssBaseline />

        <nav className={useStyles.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp>
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.menuOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: useStyles.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className={useStyles.drawer}
              variant="permanent"
              classes={{
                paper: useStyles.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <div className="homePageContainer">
          {this.changeView()}
          <HomeNavigation
            currentTab={this.state.currentView}
            changeBottomTabActiveTab={this.changeBottomBarActiveTab}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountDetail: state.accountDetail,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);

LeftMenu.propTypes = {
  accountDetail: PropTypes.objectOf(PropTypes.object),
};

LeftMenu.defaultProps = {
  accountDetail: {},
};
