import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Redirect } from 'react-router-dom';

import CustomInputField from '../../hoc/CustomInputField';
import AppLogo from '../../hoc/AppLogo';
import CustomButton from '../../hoc/CustomButton';
import CustomLink from '../../hoc/CustomLink';
import { valdaUrl, accountID } from '../../constants';
import { POSTValda } from '../../Global/Apis/ServerApi';
import {
  checkEmailisValid,
  showAlert,
  handleResponseCode,
  handleException,
  logScreenView
} from '../../utility';
import MobileSimpleTitlebar from '../../hoc/MobileSimpleTitlebar';

class SignupOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateToSignupTwo: false,
      navigateToLinkAccount: false,
      navigateToLogin: false,
      navigateToContactUs: false,
      email: '', // 'test@example.com',
      accountId: '', // '12345',
      errorEmail: false,
      emailErrorMessage: '',
      errorAccountId: false,
      accountIdErrorMessage: '',
    };
  }

  // Handle Continue for 1st signup form
  handleContinue = () => {
    // Validation for blank field or it's valid or not
    if (this.state.email === '') {
      this.setState({
        errorEmail: true,
        emailErrorMessage: "Email can't be blank.",
      });
      return;
    }
    if (!checkEmailisValid(this.state.email)) {
      this.setState({
        errorEmail: true,
        emailErrorMessage: 'Email is not valid',
      });
      return;
    }

    if (this.state.accountId === '') {
      this.setState({
        errorAccountId: true,
        accountIdErrorMessage: "Account number can't be blank.",
      });
      return;
    }

    // Api call for signupValidate
    const url = `${valdaUrl}signupValidate`;
    const apiData = {
      email: this.state.email,
      account_id: this.state.accountId,
    };

    POSTValda(url, apiData)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          localStorage.setItem(accountID, this.state.accountId);
          localStorage.setItem('email', this.state.email);
          if(response.result.already_registered === 1) {
            this.setState({
              navigateToLinkAccount: true,
            });
          } else {
            this.setState({
              navigateToSignupTwo: true,
            });
          }
          
        } else if (response && response.message) {
          showAlert('Error', response.message);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  // If user don't have account id
  handleDontHaveAccountId = () => {
    this.setState({ navigateToContactUs: true });
  };

  // want to go on login page
  handleLogin = () => {
    this.setState({ navigateToLogin: true });
  };

  // Input field handle - Email
  handleEmailChange = (newValue) => {
    this.setState({
      email: newValue,
      errorEmail: false,
      emailErrorMessage: '',
    });
  };

  // Input field handle - Account Id
  handleAccountIdChange = (newValue) => {
    this.setState({
      accountId: newValue,
      errorAccountId: false,
      accountIdErrorMessage: '',
    });
  };

  render() {
    logScreenView("Signup");
    if (this.state.navigateToSignupTwo) {
      return <Redirect to="/signuptwo" />;
    }

    if (this.state.navigateToLinkAccount) {
      return <Redirect to="/linkaccount" />;
    }

    if (this.state.navigateToLogin) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToContactUs) {
      return <Redirect to="/contactus" />;
    }

    return (
      <div>
        <div className="fixedPosition">
          <MobileSimpleTitlebar titleText="SIGN UP" />
        </div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          className="formContainer"
        >
          <AppLogo />
          <div className="centerText marginTop">
            Create an account please enter a few details.
          </div>
          <div className="centerText smallMarginTop" style={{fontSize: "0.8em"}}>
            Verification codes only sent between 8am and 8pm.
          </div>
          <CustomInputField
            fieldName="Email Address"
            fieldType="email"
            fieldValue={this.state.email}
            isError={this.state.errorEmail}
            errorMessage={this.state.emailErrorMessage}
            textChange={this.handleEmailChange}
          />
          <CustomInputField
            fieldName="Account Number"
            fieldType="number"
            fieldValue={this.state.accountId}
            isError={this.state.errorAccountId}
            errorMessage={this.state.accountIdErrorMessage}
            textChange={this.handleAccountIdChange}
          />
          <CustomButton
            btnName="CONTINUE"
            btnClick={this.handleContinue}
            showFullWidth
            endBtnIcon={<ArrowForwardIcon />}
          />
          <CustomLink
            linkText="DON'T HAVE AN ACCOUNT NUMBER? Contact Us"
            linkClick={this.handleDontHaveAccountId}
          />
          <CustomLink
            linkText="Already have an account? SIGN In"
            linkClick={this.handleLogin}
          />
        </Grid>
      </div>
    );
  }
}

export default SignupOne;
