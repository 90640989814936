/* eslint-disable arrow-body-style */
import * as actionType from './actionTypes';

// Action for setAccountDetail in Redux store
export const setAccountDetail = (accountDetails) => {
  return {
    type: actionType.GET_ACCOUNT_DETAIL_SUCCESS,
    accountDetail: accountDetails,
  };
};

// Action for when api call is failed
export const getAccountDetailFailed = () => {
  return {
    type: actionType.GET_ACCOUNT_DETAIL_FAIL,
  };
};

// Api Call for GetAccountDetailByID
export const getAccountDetail = () => {
  return (dispatch) => {
    
  }
};

// Action for Logout
export const logOut = () => {
  return {
    type: actionType.LOGOUT,
  };
};
